/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';
import { getUrlVariants } from '../../util/imageHelpers';
import { AdvancedImage, responsive } from '@cloudinary/react';
import { fit } from '@cloudinary/url-gen/actions/resize';

// Cross shaped button on the top-right corner of the image thumbnail
const RemoveImageButton = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.removeImage, className);
  return (
    <button className={css.removeImage} onClick={onClick} type='button'>
       <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2032_342)">
          <path d="M23.25 0H5.75C5.28587 0 4.84075 0.184374 4.51256 0.512563C4.18437 0.840752 4 1.28587 4 1.75V19.25C4 19.7141 4.18437 20.1592 4.51256 20.4874C4.84075 20.8156 5.28587 21 5.75 21H23.25C23.7141 21 24.1592 20.8156 24.4874 20.4874C24.8156 20.1592 25 19.7141 25 19.25V1.75C25 1.28587 24.8156 0.840752 24.4874 0.512563C24.1592 0.184374 23.7141 0 23.25 0ZM20.3691 15.1309C20.4504 15.2122 20.5148 15.3087 20.5588 15.415C20.6028 15.5212 20.6255 15.635 20.6255 15.75C20.6255 15.865 20.6028 15.9788 20.5588 16.085C20.5148 16.1913 20.4504 16.2878 20.3691 16.3691C20.2878 16.4504 20.1913 16.5148 20.085 16.5588C19.9788 16.6028 19.865 16.6255 19.75 16.6255C19.635 16.6255 19.5212 16.6028 19.415 16.5588C19.3087 16.5148 19.2122 16.4504 19.1309 16.3691L14.5 11.737L9.86906 16.3691C9.70488 16.5332 9.48219 16.6255 9.25 16.6255C9.01781 16.6255 8.79512 16.5332 8.63094 16.3691C8.46675 16.2049 8.37451 15.9822 8.37451 15.75C8.37451 15.5178 8.46675 15.2951 8.63094 15.1309L13.263 10.5L8.63094 5.86906C8.46675 5.70488 8.37451 5.48219 8.37451 5.25C8.37451 5.01781 8.46675 4.79512 8.63094 4.63094C8.79512 4.46675 9.01781 4.37451 9.25 4.37451C9.48219 4.37451 9.70488 4.46675 9.86906 4.63094L14.5 9.26297L19.1309 4.63094C19.2951 4.46675 19.5178 4.37451 19.75 4.37451C19.9822 4.37451 20.2049 4.46675 20.3691 4.63094C20.5332 4.79512 20.6255 5.01781 20.6255 5.25C20.6255 5.48219 20.5332 5.70488 20.3691 5.86906L15.737 10.5L20.3691 15.1309Z" fill="white" />
        </g>
        <defs>
          <filter id="filter0_d_2032_342" x="0" y="0" width="29" height="29" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2032_342" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2032_342" result="shape" />
          </filter>
        </defs>
      </svg>
    </button>
  );
};

const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    variants,
    dimensions,
    imgUrl,
    aspectHeight=1,
    aspectWidth=1,
    showRemoveIcon,
    onRemoveImage,
    isResponsiveImage=false,
    isSearchPage=false,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const removeButton = showRemoveIcon ? <RemoveImageButton type={"button"} onClick={onRemoveImage} /> : null;

  if (imgUrl) {
    const myImage = isResponsiveImage ? imgUrl.resize(fit()) : imgUrl;

    return (
      <div className={isSearchPage ? css.rootImgURLwithAlgin : css.rootImgURL}>
        {isResponsiveImage ? <AdvancedImage className={rootClassName} cldImg={myImage} plugins={[responsive({ steps: 200 })]} loading={"lazy"} /> :
          <img alt={alt} className={classNames(rootClassName || css.rootForImage)} srcSet={imgUrl} />}
        {removeButton}
      </div>
    )
  }

  if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  const imageVariants = image.attributes.variants;

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  return <img alt={alt} {...imgProps} />;
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  variants: arrayOf(string).isRequired,
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImage;
